import { environment } from '../../../../environments/environment';
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { StudentService } from '../../../student/services/student.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'app-feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.scss']
})
export class FeedbackPopupComponent implements OnInit {
  public attempts:any = undefined;
  sliderValue:any=5;
  feedbacktext:any;
  isLargeDevice:boolean;
  isSmallDevice:boolean;
  public allowAttempts:any = 3;
  emojiArray = [
  {imgUrl:'../../../../assets/svg/feedback/poor.svg',id:'0',value:'1', checked:'false'},
  {imgUrl:'../../../../assets/svg/feedback/bad.svg',id:'1',value:'2', checked:'false'},
  {imgUrl:'../../../../assets/svg/feedback/normal.svg',id:'2',value:'3', checked:'false'},
  {imgUrl:'../../../../assets/svg/feedback/good.svg',id:'3',value:'4', checked:'false'},
  {imgUrl:'../../../../assets/svg/feedback/best.svg',id:'4',value:'5',checked:'true'}]
  alertMsgFlag: boolean = false;
  entityId:any = ''
  constructor(public dialog: MatDialogRef<FeedbackPopupComponent>, 
    @Inject(MAT_DIALOG_DATA) public data: any,private _router: Router, 
    private _authService: AuthService,
    private studentService:StudentService,
    private _snackBar:MatSnackBar,
    private spinner:NgxSpinnerService, 
    private breakpointObserver:BreakpointObserver){
  }

  ngOnInit() {
    this.breakpointObserver
    .observe([Breakpoints.Small, Breakpoints.XSmall])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Tablet])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isSmallDevice = true;
        this.isLargeDevice = false;
      }
    });
    this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.XLarge])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isLargeDevice = true;
        this.isSmallDevice = false;
      }
    });
    this.studentService.getStudentDetails('basic').subscribe((studentData:any) => {
      this.entityId = studentData[0].entity_id
    }, error => console.log(error))
    console.log('this.sliderValue', this.sliderValue)
    this.allowAttempts = environment.assessmentAttempts,
    this.alertMsgFlag = this.data.alertMsgFlag;
    
  }

  onConfirm(){
    this.spinner.show();
    console.log('this.feedbacktext-----------', this.feedbacktext,this.sliderValue)
    //this._router.navigate(['/assessment']);
    
    let Obj = {      
      uuid: sessionStorage.getItem('userSub'),
      rating: this.sliderValue,
      feedback: this.feedbacktext
    }
    
    // commented on 04112022
    // this.studentService.saveFeedBack(Obj,this.entityId).subscribe(
    this.studentService.saveFeedBack(Obj,this.entityId).subscribe(
      data => {
        this._snackBar.open('Feedback successfully submitted', '', {
          duration: 3 * 1000,
          verticalPosition: 'bottom',
          panelClass: ['success-snackbar']
        });       
         this.dialog.close();
        this.spinner.hide();
        console.log("response",data);
      },
      error => {
        this.spinner.hide();
        console.log("error",error);
      }
    )
   
  }

  closeDialog(): void {
    // this._authService.assessmentHighlight = true;
    // this.studentService.highlightLeftMenu('/assessment','child')
    this.dialog.close();
  }
}

